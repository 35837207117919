<template>
  <v-container fluid>
    <v-data-table
      :items="teams"
      :headers="[
        { text: 'Mannschaft', value: 'team.name', sortable: false },
        { text: 'Reihenfolge', value: 'order', sortable: false, align: 'center' },
        ...disc.map(d => ({ text: d.shortName, value: d._id, sortable: false }))
      ]"
      hide-default-footer
      :items-per-page="-1"
      mobile-breakpoint="0"
    >
      <template #item="{item}">
        <tr>
          <td>{{ item.team.name }}</td>
          <td style="text-align: center;">
            <base-edit-dialog
              :value="item.order"
              :id="item.team._id"
              label="Reihenfolge"
              @input="({ value, id }) => setorder(id, parseInt(value))"
            />
          </td>
          <td v-for="d in disc" :key="d._id">
            <v-icon v-if="item._startdiscipline === d._id">far fa-check</v-icon>
            <v-btn
              v-else
              text
              fab
              x-small
              @click="setstartdiscipline(item.team._id, d._id)"
            >
              <v-icon color="grey lighten-2">far fa-xmark</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { useGraphQL } from '@/plugins/graphql'
import gql from 'graphql-tag'

export default {
  name: 'riegen',

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    },
    e: {
      type: Object,
      required: true
    },
    df: {
      type: Array,
      required: true
    }
  },

  computed: {
    teams () {
      return this.e.teams.slice().sort((a, b) => {
        if (!a.order && b.order) return 1
        if (a.order && !b.order) return -1
        if (a.order && b.order) return a.order < b.order ? -1 : 1
        return a.team.name < b.team.name ? -1 : 1
      })
    },
    disc () {
      return (this.df?.map(d => ({
        ...d,
        __typename: undefined,
        active: !!this.e?.disciplines?.find(ed => ed._discipline === d._id),
        order: this.e?.disciplines?.find(ed => ed._discipline === d._id)?.order
      })) || [])
        .filter(d => d.active)
        .sort((a, b) => {
          return a.order < b.order ? -1 : 1
        })
    }
  },

  methods: {
    set (item) {
      this.setorder(item.team._id, 1)
      this.setorder(this.e?.teams?.find(t => t.team?._id !== item.team._id)?.team?._id, 2)
    },
    setorder (team, order) {
      this.mutate({
        mutation: gql`
          mutation($id: UUID!, $team: UUID!, $order: Int!) {
            StbW2023WkTeamOrderUpdate(id: $id, team: $team, order: $order) {
              _id
            }
          }
        `,
        variables: {
          id: this.id,
          team,
          order
        }
      })
    },
    setstartdiscipline (tid, did) {
      this.mutate({
        mutation: gql`
          mutation($id: UUID!, $tid: UUID!, $did: UUID!) {
            StbW2023WkTeamStartdisciplineUpdate(id: $id, team: $tid, startdiscipline: $did) {
              _id
            }
          }
        `,
        variables: {
          id: this.id,
          tid,
          did
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
